<template>
  <div class="Journalism">
    <div class="header">
      <div class="conter">
        <div>医疗行业新闻</div>
        <p>汇集行业资讯，掌握行业动态</p>
      </div>
    </div>
    <div class="conter">
      <el-tabs style="margin-top: 50px">
        <el-tab-pane>
          <span slot="label"
            ><i class="linkIcon iconfont icon-weibiaoti--"></i> 公司新闻</span
          >
          <div class="company">
            <div
              class="company-item"
              v-for="(item, index) in newsInfoListData"
              :key="index"
              @click="gonewsdetails(item.newsId)"
            >
              <img :src="item.newsImg" alt="" />
              <h2>{{ item.newsTitle }}</h2>
              <p>{{ item.createTime }}</p>
            </div>
          </div>
          <!-- 分页 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            prev-text="上一页"
            next-text="下一页"
            :current-page="pageNumber"
            :page-sizes="[12, 30, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane>
          <span slot="label"
            ><i class="linkIcon iconfont icon-dongtai"></i> 行业动态</span
          >
          <div class="company">
            <div
              class="company-item"
              v-for="(item, index) in dynamicsListData"
              :key="index"
              @click="gonewsdetails(item.newsId)"
            >
              <img :src="item.newsImg" alt="" />
              <h2>{{ item.newsTitle }}</h2>
              <p>{{ item.createTime }}</p>
            </div>
          </div>
          <!-- 分页 -->
          <el-pagination
            @size-change="dynamicsHandleSizeChange"
            @current-change="dynamicsHandleCurrentChange"
            prev-text="上一页"
            next-text="下一页"
            :current-page="dynamicspageNumber"
            :page-sizes="[12, 30, 50, 100]"
            :page-size="dynamicspageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalIndus"
          >
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
    </div>
    <footerBottom />
  </div>
</template>

<script>
import { getCheckedNewsInfo } from '../../api/journalism'
import footerBottom from '../../components/webFooter/footerBottom.vue'
export default {
  components: {
    footerBottom
  },
  data() {
    return {
      newsInfoListData: [],
      dynamicsListData: [],
      pageNumber: 1,
      pageSize: 12,
      total: 0,
      dynamicspageNumber: 1,
      dynamicspageSize: 12,
      dynamicstotal: 0,
      totalIndus: 0
    }
  },
  methods: {
    async getCheckedNewsInfoList() {
      const { code, data } = await getCheckedNewsInfo({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        newsType: '公司动态'
      })
      if (code === '0000') {
        this.total = data.count
        this.newsInfoListData = data.news
      }
    },
    async getIndustryDynamicsList() {
      const { code, data } = await getCheckedNewsInfo({
        pageNumber: this.dynamicspageNumber,
        pageSize: this.dynamicspageSize,
        newsType: '行业资讯'
      })
      if (code === '0000') {
        this.totalIndus = data.count
        this.dynamicsListData = data.news
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getCheckedNewsInfoList()
    },
    handleCurrentChange(val) {
      this.pageNumber = val
      this.getCheckedNewsInfoList()
    },
    dynamicsHandleSizeChange(val) {
      this.dynamicspageSize = val
      console.log(this.dynamicspageSize, 'dynamicspageSize')
      this.getIndustryDynamicsList()
    },
    dynamicsHandleCurrentChange(val) {
      this.dynamicspageNumber = val
      this.getIndustryDynamicsList()
    },
    // 去新闻详情
    gonewsdetails(newsId) {
      const news = {}
      news.newsId = newsId
      this.$router.push({
        path: '/web/journalism/details',
        query: news
      })
    }
  },
  created() {
    this.getCheckedNewsInfoList()
    this.getIndustryDynamicsList()
  }
}
</script>

<style lang="less" scoped>
.Journalism {
  height: 100%;
  .header {
    width: 100%;
    height: 480px;
    background: url('../../assets/images/web/xinwenzhongxin_.png') no-repeat;
    // background-size: 100% 100%;
    background-size: cover;
    .conter {
      padding-left: 250px;
      box-sizing: border-box;
      div {
        padding-top: 140px;
        color: #0b1a35;
        font-size: 74px;
        font-family: Source Han Sans CN;
        letter-spacing: 4px;
      }
      p {
        margin: 0;
        font-size: 34px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #0b1a35;
        padding-top: 50px;
      }
    }
  }
  .el-tab-pane {
    .company {
      width: 1200px;
      margin-bottom: 30px;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-around;
      .company-item {
        margin-right: 20px;
        cursor: pointer;
        width: 380px;
        margin-top: 38px;
        height: 390px;
        padding: 27px;
        box-sizing: border-box;
        box-shadow: 0px 7px 24px 0px rgba(12, 41, 72, 0.16);
        img {
          width: 339px;
          height: 213px;
          object-fit: cover;
        }
        h2 {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #3c3c3c;
          line-height: 30px;
          // padding: 16px 0 10px 0;
          overflow: hidden;
          // display: -webkit-box;
          // -webkit-box-orient: vertical;
          // -webkit-line-clamp: 2;
          -ms-text-overflow: ellipsis;
          text-overflow: ellipsis;
          // margin: 0 !important;
          margin: 16px 0 10px 0;
        }
        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #9a979b;
        }
      }
      .company-item :nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  .el-pagination {
    float: right;
    margin: 60px 0 114px 0;
  }
  /deep/.el-tabs__item {
    font-size: 22px;
    padding: 0;
    // display: flex;
    // justify-content: center;
    span {
      // font-weight: 800;
    }
  }
  .linkIcon {
    // color: #adadad;
    font-size: 30px;
    margin-right: 15px;
  }
  // 切换统一样式
  /deep/.el-pagination .btn-prev {
    color: #fff;
    background: #0c2948;
  }
  /deep/.el-pagination .btn-next,
  /deep/.el-pagination .btn-prev {
    color: #fff;
    background: #0c2948;
  }
  /deep/.el-tabs__nav-scroll {
    width: 590px;
    margin: auto;
    padding-left: 72px !important;
  }
  /deep/.el-tabs__nav-wrap::after {
    content: '';
    width: 0;
    background-color: #fff !important;
  }
  /deep/.el-tabs__item {
    width: 245px;
    text-align: center;
    height: 66px;
    line-height: 66px;
    text-align: center;
    background-color: #f5f5f5;
    span {
      line-height: 0px;
      display: inline-block;
      // .icon-dongtai{
      //   margin-top: 2px !important;
      // }
    }
  }
  /deep/.el-tabs__item.is-active {
    background: #0c2948;
    color: #d7dce1;
    border: none;
  }
  /deep/.el-tabs__active-bar {
    height: 0px;
  }
  /deep/.el-pager li {
    margin-left: 10px;
  }
  /deep/.el-pager li:last-child {
    margin-right: 10px;
  }
  .iconfont {
    font-size: 26px;
  }
}
</style>
